export interface Fixture {
  _id: string;
  fixtureId: string;
  homeTeam: string;
  awayTeam: string;
  timestamp: number;
}

export interface UpsertFixtureBody {
  fixtureId: string;
  homeTeam: string;
  awayTeam: string;
  timestamp: number;
}

export enum FixtureType {
  ROUND_OF_16 = "Osmifinále",
  QUARTER_FINAL = "Čtvrtfinále",
  SEMI_FINAL = "Semifinále",
  BRONZE_MEDAL_GAME = "Utkání o bronz",
  FINAL = "Finále",
}
