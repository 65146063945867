import React from "react";
import { AppPageWrapper } from "../components/common/AppPageWrapper";
import groups from "../flashdata/football/groups.json";
import { GroupRow } from "../components/GroupRow";

export function Groups() {
  return (
    <AppPageWrapper>
      {groups.DATA.map(({ GROUP, GROUP_ID, ROWS }) => (
        <GroupRow
          key={GROUP_ID}
          group={GROUP}
          teams={ROWS.map(({ TEAM_NAME, TEAM_IMAGE_PATH }) => ({
            teamName: TEAM_NAME,
            logo: TEAM_IMAGE_PATH,
          }))}
        />
      ))}
    </AppPageWrapper>
  );
}
