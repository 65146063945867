import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCookies } from "react-cookie";

export function CookieConsent() {
  const [, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setCookie("cookieConsent", true, { path: "/", expires: oneYearFromNow });
  };

  return (
    <Dialog open={true} fullWidth={true}>
      <DialogTitle>Tato webová stránka používá cookies</DialogTitle>
      <DialogContent>
        <Typography sx={{ marginBottom: 2 }}>
          Používáním této webové stránky souhlasíte s nastavením cookies.
        </Typography>
        <Accordion variant="outlined">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>Více o nastavení souborů cookies</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption" sx={{ display: "block" }}>
              Na našem webu používáme cookies pro zlepšení vašeho prohlížení a
              poskytování personalizovaného obsahu a služeb. Cookies jsou malé
              textové soubory, které jsou umístěny na vašem počítači nebo
              mobilním zařízení při návštěvě webové stránky. Pomáhají webu
              zapamatovat si vaše preference, nastavení a přihlašovací údaje a
              poskytují vám plynulejší zážitek.
            </Typography>
            <Typography
              variant="caption"
              sx={{ display: "block", marginTop: 2 }}
            >
              Na našem webu používáme několik typů cookies, včetně dočasných
              cookies, trvalých cookies a cookies třetích stran. Dočasné cookies
              jsou smazány, když zavřete prohlížeč, zatímco trvalé cookies
              zůstávají na vašem zařízení po určitou dobu. Cookies třetích stran
              jsou umístěny poskytovatelem služeb třetí strany, například Google
              Analytics, aby sbíraly data o vašem prohlížení a poskytovaly nám
              analýzy pro zlepšení našeho webu. Sbíráme pouze data, která jsou
              nezbytná pro poskytnutí co nejlepšího uživatelského zážitku a pro
              zlepšení výkonu našeho webu.
            </Typography>
            <Typography
              variant="caption"
              sx={{ display: "block", marginTop: 2 }}
            >
              Google Analytics je jedním ze skriptů třetích stran, které
              používáme na našem webu. Pomáhá nám pochopit, jak návštěvníci
              používají naše stránky, například které stránky jsou nejčastěji
              navštěvovány a jak dlouho. Tato data jsou použita pro zlepšení
              funkčnosti a obsahu našeho webu. Google Analytics používá cookies
              pro sběr informací o vašem prohlížení, včetně vaší IP adresy a
              stránek, které navštěvujete na našem webu. Tyto informace jsou
              uloženy na serverech Google a mohou být sdíleny s dalšími službami
              Google.
            </Typography>
            <Typography
              variant="caption"
              sx={{ display: "block", marginTop: 2 }}
            >
              Pokud nechcete, aby Google Analytics sbíral data o vašem
              prohlížení, můžete se odhlásit instalací doplňku Google Analytics
              Opt-out Browser Add-on. Tento doplněk brání JavaScriptu Google
              Analytics (ga.js, analytics.js a dc.js) ve sdílení informací o
              aktivitě návštěv s Google Analytics.
            </Typography>
            <Typography
              variant="caption"
              sx={{ display: "block", marginTop: 2 }}
            >
              Celkově je naše použití cookies a skriptů třetích stran navrženo
              tak, aby zlepšilo váš zážitek z prohlížení a poskytlo vám
              personalizovaný obsah a služby. Vaše soukromí bereme vážně a
              budeme sbírat pouze data, která jsou nezbytná pro poskytnutí co
              nejlepšího uživatelského zážitku. Pokud máte jakékoliv otázky nebo
              obavy ohledně našeho použití cookies nebo skriptů třetích stran,
              kontaktujte nás prosím prostřednictvím emailu.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={giveCookieConsent}>
          Souhlasím
        </Button>
      </DialogActions>
    </Dialog>
  );
}
