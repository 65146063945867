import React, { Fragment, useState } from "react";
import fixtures from "../flashdata/football/fixtures.json";
import playoff from "../flashdata/football/playoff.json";
import {
  Alert,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  Stack,
  Switch,
} from "@mui/material";
import { AppPageWrapper } from "../components/common/AppPageWrapper";
import { MatchRow } from "../components/MatchRow";
import { useMutation, useQuery } from "react-query";
import { ResultsApi } from "../api/ResultsApi";
import { Result, UpsertResultBody } from "../types/results";
import { TipsApi } from "../api/TipsApi";
import { useAuth } from "../hooks/useAuth";
import { Tip } from "../types/tips";
import { AxiosError } from "axios";
import { useSnackbar } from "../hooks/useSnackbar";
import { isOldTip } from "../utils/fixtureUtils";
import { Fixture, FixtureType } from "../types/playoff";
import { PlayoffApi } from "../api/PlayoffApi";

type ScoreResult = Pick<Result, "home" | "away">;

export function Schedule() {
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth();
  const userId = user?.userId ?? "";
  const [tips, setTips] = useState<Tip[]>();
  const [results, setResults] = useState<Result[]>([]);
  const [playoffFixtures, setPlayoffFixtures] = useState<Fixture[]>([]);
  const [oldTipsVisible, setOldTipsVisible] = useState(false);

  const { refetch: refetchTips } = useQuery(
    ["api/tips/:userId", userId],
    () => TipsApi.getTipsByUserId(userId),
    {
      enabled: user !== null,
      onSuccess: ({ data }) => setTips(data.tips),
    }
  );

  const { refetch: refetchResults } = useQuery(
    ["api/results"],
    ResultsApi.getResults,
    {
      onSuccess: ({ data }) => setResults(data.results),
    }
  );

  const { mutate: upsertTip } = useMutation(TipsApi.upsertTip, {
    onSuccess() {
      refetchTips();
      showSnackbar("Tip byl aktualizován.", "success");
    },
    onError: (err: AxiosError<{ message: string }>) => {
      const message = err.response?.data.message;
      if (message) {
        showSnackbar(message, "error");
      }
    },
  });

  const { mutate: upsertResult } = useMutation(
    (body: UpsertResultBody) => ResultsApi.upsertResult(body),
    {
      onSuccess() {
        refetchResults();
        showSnackbar("Výsledek byl uložen.", "success");
      },
      onError: (err: AxiosError<{ message: string }>) => {
        const message = err.response?.data.message;
        showSnackbar(message || "Při ukládání došlo k neznámé chybě.", "error");
      },
    }
  );

  const { mutate: deleteResult } = useMutation(
    (_id: string) => ResultsApi.deleteResult(_id),
    {
      onSuccess() {
        refetchResults();
        showSnackbar("Výsledek byl vymazán.", "info");
      },
      onError: (err: AxiosError<{ message: string }>) => {
        const message = err.response?.data.message;
        showSnackbar(message || "Došlo k neznámé chybě.", "error");
      },
    }
  );

  useQuery(["api/fixtures"], PlayoffApi.getFixtures, {
    onSuccess: ({ data }) => setPlayoffFixtures(data.fixtures),
  });

  function handleSubmitResult(
    scoreSubmitted: ScoreResult | null,
    fixtureId: string,
    result?: Result
  ) {
    if (scoreSubmitted === null && result) {
      deleteResult(result._id);
    } else if (scoreSubmitted !== null) {
      upsertResult({ fixtureId, ...scoreSubmitted });
    }
  }

  return (
    <AppPageWrapper>
      <FormGroup>
        <FormControlLabel
          sx={{ padding: "10px 5px" }}
          control={
            <Switch
              size="small"
              value={oldTipsVisible}
              onChange={() => setOldTipsVisible((prev) => !prev)}
            />
          }
          label="Zobrazit všechny odehrané zápasy"
        />
      </FormGroup>
      <List
        sx={{
          width: "100%",
          maxWidth: 860,
          bgcolor: "background.paper",
        }}
      >
        {fixtures.EVENTS.map(
          ({
            EVENT_ID,
            START_TIME,
            HOME_NAME,
            HOME_IMAGES,
            AWAY_NAME,
            AWAY_IMAGES,
          }) => {
            const result = results?.find(
              ({ fixtureId }) => fixtureId === EVENT_ID
            );
            const tip = tips?.find(({ fixtureId }) => fixtureId === EVENT_ID);
            if (!oldTipsVisible && isOldTip(START_TIME)) {
              return null;
            }
            return (
              <Fragment key={EVENT_ID}>
                <MatchRow
                  homeLabel={HOME_NAME}
                  awayLabel={AWAY_NAME}
                  homeLogo={HOME_IMAGES?.[0]}
                  awayLogo={AWAY_IMAGES?.[0]}
                  timestamp={START_TIME}
                  result={result}
                  tip={tip}
                  onSubmitTip={(score) =>
                    upsertTip({ fixtureId: EVENT_ID, ...score })
                  }
                  onSubmitResult={(scoreSubmitted) =>
                    handleSubmitResult(scoreSubmitted, EVENT_ID, result)
                  }
                />
                <Divider component="li" />
              </Fragment>
            );
          }
        )}
      </List>

      <Stack
        sx={{
          width: "100%",
          maxWidth: 860,
        }}
      >
        <Alert severity="warning">
          Tipy vyřazovací fáze turnaje se vyhodnocují podle výsledků daných
          zápasů po základní hrací době (případné prodloužení nebo penaltový
          rozstřel nebudou mít na bodování žádný vliv).
        </Alert>
      </Stack>

      {/* PLAYOFF */}
      <List
        sx={{
          width: "100%",
          maxWidth: 860,
          bgcolor: "background.paper",
        }}
      >
        {playoff.response.map(({ fixture, teams }) => {
          const result = results?.find(
            ({ fixtureId }) => fixture.id === fixtureId
          );
          const tip = tips?.find(({ fixtureId }) => fixture.id === fixtureId);
          const storedFixture = playoffFixtures.find(
            ({ fixtureId }) => fixtureId === fixture.id
          );
          const homeTeam = storedFixture?.homeTeam;
          const awayTeam = storedFixture?.awayTeam;
          return (
            <Fragment key={fixture.id}>
              <MatchRow
                homeLabel={homeTeam || teams.home.placeholder}
                awayLabel={awayTeam || teams.away.placeholder}
                timestamp={fixture.timestamp}
                result={result}
                tip={tip}
                notKnownTeams={!homeTeam && !awayTeam}
                matchType={fixture.type as FixtureType}
                onSubmitTip={(score) =>
                  upsertTip({ fixtureId: fixture.id, ...score })
                }
                onSubmitResult={(scoreSubmitted) =>
                  handleSubmitResult(scoreSubmitted, fixture.id, result)
                }
              />
              <Divider component="li" />
            </Fragment>
          );
        })}
      </List>
    </AppPageWrapper>
  );
}
