import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { AppPageWrapper } from "../components/common/AppPageWrapper";
import { useAuth } from "../hooks/useAuth";
import { Rules } from "../components/Rules";
import { AnonymousInfo } from "../components/AnonymousInfo";

export function Home() {
  const { user } = useAuth();

  return (
    <AppPageWrapper>
      <Box sx={{ padding: "20px" }}>
        <img
          src="https://www.flashscore.com/res/image/data/2i69RRV1-Ug8IjIuG.png"
          alt="Euro Championship 2024"
        />
      </Box>
      <Typography variant="h4" gutterBottom>
        {user ? "Pravidla" : "Tipovačka"}
      </Typography>
      {user ? <Rules /> : <AnonymousInfo />}
      <Typography sx={{ textAlign: "center", padding: "10px" }}>
        Oficiální stránky Euro 2024 naleznete{" "}
        <Link
          sx={{ cursor: "pointer", textDecoration: "none" }}
          href="https://www.uefa.com/euro2024/"
          target="_blank"
        >
          zde
        </Link>
        .
      </Typography>
    </AppPageWrapper>
  );
}
