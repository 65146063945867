const ONE_HOUR = 1000 * 60 * 60;
const ONE_DAY = 24 * ONE_HOUR;

export function isMatchAlreadyStarted(timestamp: number) {
  return timestamp * 1000 < new Date().getTime();
}

export function getLocalString(timestamp: number) {
  return new Date(timestamp * 1000).toLocaleString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function getShortName(name: string) {
  if (name === "Slovensko") {
    return "SVK";
  }
  return name.substring(0, 3).toUpperCase();
}

export function isOldTip(timestamp: number) {
  return timestamp * 1000 < new Date().getTime() - ONE_DAY;
}
