import React, { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthContext, useAuthContext } from "./context/authContext";
import { SnackbarContext, useSnackbarContext } from "./context/snackbarContext";
import { DialogContext, useDialogContext } from "./context/dialogContext";
import { CookieConsent } from "./components/CookieConsent";
import { useCookies } from "react-cookie";

export function AppProviders({ children }: PropsWithChildren) {
  const [cookies] = useCookies(["cookieConsent"]);

  // Create a client provider
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { retry: 1, refetchOnWindowFocus: false },
    },
  });

  // Context
  const auth = useAuthContext();
  const snackbar = useSnackbarContext();
  const dialog = useDialogContext();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider value={auth}>
        <SnackbarContext.Provider value={snackbar}>
          <DialogContext.Provider value={dialog}>
            {children}
            {!cookies.cookieConsent && <CookieConsent />}
          </DialogContext.Provider>
        </SnackbarContext.Provider>
      </AuthContext.Provider>
    </QueryClientProvider>
  );
}
