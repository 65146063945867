import React from "react";
import { Avatar, Stack, Paper, Typography, Box } from "@mui/material";

interface Team {
  logo: string;
  teamName: string;
}

interface GroupRowProps {
  group: string;
  teams: Team[];
}

export function GroupRow({ group, teams }: GroupRowProps) {
  return (
    <Box
      margin={{ xs: 1, md: 2 }}
      padding={{ xs: 1, md: 1 }}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        alignItems: "center",
        bgcolor: "background.paper",
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      }}
    >
      <Typography variant="h6">{group}</Typography>
      <Stack
        direction={{ xs: "row", sm: "row" }}
        spacing={{ xs: 0, md: 1 }}
        display="grid"
        gridTemplateColumns="1fr 1fr 1fr 1fr"
      >
        {teams.map(({ teamName, logo }) => (
          <Paper
            sx={{
              display: "grid",
              justifyItems: "center",
              gap: "4px",
              width: "24vw",
              maxWidth: "200px",
              padding: "1vw",
              boxShadow: "none",
            }}
            key={teamName}
          >
            <Typography
              variant="h6"
              fontSize={{ xs: "0.8rem", md: "1rem" }}
              sx={{ textAlign: "center" }}
            >
              {teamName}
            </Typography>
            <Avatar
              sx={{
                width: "60px",
                height: "40px",
              }}
              src={logo}
              variant="square"
            />
          </Paper>
        ))}
      </Stack>
    </Box>
  );
}
