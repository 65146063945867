import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";

interface AppPageWrapperProps extends PropsWithChildren {
  isTipTable?: boolean;
}

export function AppPageWrapper({ isTipTable, children }: AppPageWrapperProps) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: {
          xs: isTipTable ? "column-reverse" : "column",
          md: "column",
        },
        alignItems: "center",
        paddingTop: { xs: 0, md: "1rem" },
        paddingBottom: "2rem",
      }}
    >
      {children}
    </Box>
  );
}
